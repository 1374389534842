@import url(https://fonts.googleapis.com/css?family=Roboto:400,600,700,800,900);
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #efefef;
  font-family: "Roboto", "open sans", "Arial";
  font-weight: 400;
}

#root {
  background-color: #efefef;
  -webkit-animation: fade-in 1s ease;
          animation: fade-in 1s ease;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

*:focus {
  outline: none;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #8e8e8e;
}

@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes rotate {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate {
  0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes fade-in-translate {
	0% {
		opacity: 0;
		-webkit-transform: translateY(50px);
		        transform: translateY(50px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes fade-in-translate {
	0% {
		opacity: 0;
		-webkit-transform: translateY(50px);
		        transform: translateY(50px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes fade-in-translate-low {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes fade-in-translate-low {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		        transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes fade-in-translate-left {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-10px);
		        transform: translateX(-10px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@keyframes fade-in-translate-left {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-10px);
		        transform: translateX(-10px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@-webkit-keyframes fade-in-zoom {
	0% {
		opacity: 0;
		-webkit-transform: scale(.8);
		        transform: scale(.8);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes fade-in-zoom {
	0% {
		opacity: 0;
		-webkit-transform: scale(.8);
		        transform: scale(.8);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes wave-rotate {
	0% {
		-webkit-transform: rotate(-20deg);
		        transform: rotate(-20deg);
	}
	100% {
		-webkit-transform: rotate(60deg);
		        transform: rotate(60deg)
	}
}

@keyframes wave-rotate {
	0% {
		-webkit-transform: rotate(-20deg);
		        transform: rotate(-20deg);
	}
	100% {
		-webkit-transform: rotate(60deg);
		        transform: rotate(60deg)
	}
}

@-webkit-keyframes rocket-translate {
	0% {
		-webkit-transform: rotate(0deg) translateY(5px);
		        transform: rotate(0deg) translateY(5px);
	}
	100% {
		-webkit-transform: rotate(10deg) translateY(-5px);
		        transform: rotate(10deg) translateY(-5px);
	}
}

@keyframes rocket-translate {
	0% {
		-webkit-transform: rotate(0deg) translateY(5px);
		        transform: rotate(0deg) translateY(5px);
	}
	100% {
		-webkit-transform: rotate(10deg) translateY(-5px);
		        transform: rotate(10deg) translateY(-5px);
	}
}

