@-webkit-keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes rotate {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fade-in-translate {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade-in-translate-low {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade-in-translate-left {
	0% {
		opacity: 0;
		transform: translateX(-10px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fade-in-zoom {
	0% {
		opacity: 0;
		transform: scale(.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes wave-rotate {
	0% {
		transform: rotate(-20deg);
	}
	100% {
		transform: rotate(60deg)
	}
}

@keyframes rocket-translate {
	0% {
		transform: rotate(0deg) translateY(5px);
	}
	100% {
		transform: rotate(10deg) translateY(-5px);
	}
}
